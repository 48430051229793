<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="8" lg="10" md="10" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Activité" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail de l'activité</div>
              </v-row>
            </v-card-title>

            <!-- le nom -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">nom</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom"
                    dense
                    v-model="name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le métier -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">métier</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner un métier"
                    :items="metiers"
                    item-text="name"
                    item-value="id"
                    no-data-text="aucun métier"
                    v-model="metierId"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le nom digital -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">nom digital</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom digital"
                    dense
                    v-model="digitalName"
                    @blur="digitalNameFieldOut"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le nom complet -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="capitalize font-weight-regular">nom complet</div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <v-text-field
                    placeholder="Le nom complet"
                    dense
                    v-model="fullName"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Libellé -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">libellé</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le libellé"
                    dense
                    v-model="label"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Slug -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">slug</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le slug"
                    dense
                    v-model="slug"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Les dops -->
            <v-card-text>
              <v-row no-gutters align="start">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="capitalize font-weight-regular">
                    DOP - Périmètre
                  </div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <div
                    v-for="(dop, i) in associateDops"
                    :key="i"
                    v-show="associateDops.length > 0"
                  >
                    <v-row
                      no-gutters
                      align="start"
                      justify="space-between"
                      class="mb-1"
                    >
                      <v-col cols="7">
                        <div v-if="dop.error" class="red--text" v-html="dop.label"></div>
                        <div v-else>{{ dop.label }}</div>
                      </v-col>
                      <v-col cols="4">
                        <div v-if="dop.error" class="red--text">
                          {{ dop.perimeter }}
                        </div>
                        <div v-else>{{ dop.perimeter }}</div>
                      </v-col>
                      <v-col cols="1" align="end">
                        <v-btn
                          icon
                          color="primary"
                          @click="removeAssociateDop(dop)"
                        >
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn></v-col
                      >
                    </v-row>
                  </div>
                  <!-- Affichage de la liste des dops associables -->
                  <div>
                    <v-row no-gutters justify="space-between" align="start">
                      <v-col cols="7" class="px-1">
                        <v-autocomplete
                          v-model="selectedDop"
                          :items="availableDops"
                          item-text="email"
                          return-object
                          placeholder="Associer un dop"
                          hint="Associer un dop, inscrire son périmètre puis appuyer sur le bouton '+'"
                          clearable
                          class="ma-0 pa-0"
                          no-data-text="aucun dop"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4" class="px-1">
                        <v-text-field
                          placeholder="Le périmètre"
                          v-model="perimeterSelectedDop"
                          class="ma-0 pa-0"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1" align="end">
                        <v-btn
                          color="primary"
                          icon
                          @click="addDop(selectedDop)"
                          :disabled="!canAddDop"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Les sous activitées -->
            <v-card-text>
              <v-row no-gutters class="mb-5 mt-1" align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="capitalize font-weight-regular mt-2">
                    les sous-activités associées
                  </div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <!-- Affichage des sous-activités à associé -->
                  <div v-for="(sub, i) in associateSubActivities" :key="i">
                    <v-row
                      no-gutters
                      align="center"
                      justify="space-between"
                      class="mb-1"
                    >
                      <div>
                        {{ sub.digitalName }}
                      </div>
                      <v-btn
                        icon
                        color="primary"
                        @click="removeAssociateSubActivity(sub)"
                      >
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </v-row>
                  </div>

                  <!-- Affichage de la liste des sous-activités associables -->
                  <div>
                    <v-row no-gutters align="start" justify="space-between">
                      <v-autocomplete
                        v-model="selectedSubActivity"
                        :items="availableSubActivities"
                        item-text="digitalName"
                        return-object
                        placeholder="Associer une sous-activité"
                        hint="Associer une sous-activité puis appuyer sur le bouton '+'"
                        clearable
                        v-on:keyup.enter="addSubActivity(selectedSubActivity)"
                        class="ma-0 pa-0"
                        no-data-text="aucune sous-activité"
                      >
                      </v-autocomplete>
                      <v-btn
                        color="primary"
                        icon
                        @click="addSubActivity(selectedSubActivity)"
                        :disabled="!selectedSubActivity"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
              >
                <div class="capitalize">enregistrer</div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      ><span v-html="snackbarMessage"></span></v-snackbar
    >

    <v-dialog
      v-model="showSubActivityLinkedDialog"
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Suppression impossible
        </v-card-title>

        <v-card-text class="mt-4">
          Certains établissements sont actuellement associés à cette
          sous-activité. Veuillez d'abord retirer cette sous-activité de ces
          établissements.
        </v-card-text>

        <v-card-text>
          <v-list dense>
            <v-list-item
              v-for="(establishment, index) in subActivityLinkedWith"
              :key="'E' + index"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="establishment.digitalName"
                ></v-list-item-title>

                <v-list-item-subtitle
                  v-text="establishment.internalName"
                ></v-list-item-subtitle>
              </v-list-item-content>

              <!-- <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action> -->
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="showSubActivityLinkedDialog = false"
          >
            FERMER
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { AppConsulterModifierService } from "@/service/conf/app_consulter_modifier_service.js";
import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";
import { MetierService } from "@/service/sfr/metier_service.js";
import SubActivitiesService from "@/service/sfr/sub_activity_service.js";
import { UsersService } from "@/service/user/dg_user_service.js";
import { ViewActivityService } from "./ViewActivityService.js";

import { areTheSame, trimString } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditActivity",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      serviceActivities: null,
      serviceMetiers: null,
      serviceSubActivities: null,
      serviceConf: null,
      serviceUsers: null,
      serviceEstablishment: null,

      /** Service métier */
      viewActivityService: null,

      /** l'identifiant à éditer */
      activityId: null,

      /**La donnée originale */
      source: null,
      /**le nom */
      name: null,
      /**identifiant du métier sélectionné */
      metierId: null,
      /** le nom digital de l'activité */
      digitalName: null,
      /** Le nom complet de l'activité */
      fullName: null,
      /** le libellé de l'activité */
      label: null,
      /** le slug de l'activité */
      slug: null,
      /** les dops associés initialement*/
      sourceAssociateDops: [],
      /** les sous-activités asscociées initiales */
      sourceAssociateSubActivities: [],

      /** les dops associés */
      associateDops: [],
      /**les sous-activités associées */
      associateSubActivities: [],

      /** tous les users dops */
      allDops: [],
      /** toutes les sous-activités (référence) */
      allSubActivities: [],

      /** le dop sélectionné par l'ajout */
      selectedDop: null,
      perimeterSelectedDop: null,
      /**la sous-activité sélectionnée pour l'ajout */
      selectedSubActivity: null,

      /** les métiers disponibles pour l'activité */
      metiers: [],

      /** L'id de la fonction dop */
      idFunctionDop: null,

      showSubActivityLinkedDialog: false,

      /**les établissements liés à une sous-activité */
      subActivityLinkedWith: [],
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // /** Récupération de l'id de la fonction dop dans la conf */
        this.idFunctionDop = await this.serviceConf.getIdDop();

        // Récupération de la liste des users dops
        this.allDops = await this.serviceUsers.getUsersFunctionDop(
          this.idFunctionDop.id
        );

        // Récupération de l'activité
        let activity = await this.serviceActivities.getActivityById(
          this.activityId
        );
        this.source = JSON.parse(JSON.stringify(activity));

        // Récupération de tous les métiers
        this.metiers = await this.serviceMetiers.getAll();

        // Récupération des dops de l'activité
        let loadedDops = await this.viewActivityService.loadDops(this.activityId, this.idFunctionDop.id);
        this.associateDops = loadedDops.allDops;        
        this.sourceAssociateDops = [...this.associateDops];

        let errorMessage = this.viewActivityService.getDopErrorMessage(loadedDops.dopsWithNoMoreDopFunction, loadedDops.dopsOut);
        if (errorMessage) {
          this.addErrorToSnackbar(errorMessage)
        }

        // Récupération de toutes les sous-activités
        this.allSubActivities =
          await this.serviceSubActivities.getAllSubActivities();
        this.associateSubActivities = [];
        //on charge la liste des sous-activités
        await this.serviceActivities
          .getSubActivitiesOfActivity(this.activityId)
          .then((sub) => {
            this.associateSubActivities = sub;
          });
        this.sourceAssociateSubActivities = [...this.associateSubActivities];

        this.init();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**initialiser les données à partir de l'objet source */
    init() {
      this.name = this.source.name;
      this.metierId = this.source.metier;
      this.digitalName = this.source.digitalName;
      this.fullName = this.source.fullName;
      this.label = this.source.label;
      this.slug = this.source.slug;
    },

    /** Retirer un dop associé */
    removeAssociateDop(dop) {
      this.associateDops.splice(
        this.associateDops.findIndex((d) => d.idDop == dop.idDop),
        1
      );
    },

    /** Ajouter un dop associé */
    addDop(dop) {
      if (!dop) return;
console.log(dop);
      // Association des datas pour fabrication d'un dop
      let dopAdd = {};
      dopAdd.idDop = dop.uuid;
      dopAdd.label = dop.email;
      dopAdd.perimeter = this.perimeterSelectedDop;

      this.associateDops.push(dopAdd);
      this.selectedDop = null;
      this.perimeterSelectedDop = null;
    },

    /**retirer une sous_activité associée */
    async removeAssociateSubActivity(subActivity) {
      this.subActivityLinkedWith = [];

      let establishments =
        await this.serviceEstablishment.establishmentsWithSubActivity(
          subActivity.id
        );

      let establishmentIds = establishments.map((e) => e.id);

      if (establishments.length > 0) {
        let list = await this.serviceEstablishment.getEstablishments();
        let targets = list.filter((e) => establishmentIds.includes(e.id));

        this.subActivityLinkedWith = targets;
        this.showSubActivityLinkedDialog = true;
        return;
      }

      this.associateSubActivities.splice(
        this.associateSubActivities.findIndex((r) => r.id == subActivity.id),
        1
      );
    },
    /**Ajouter une sous-activité associée */
    addSubActivity(subActivity) {
      if (!subActivity) return;

      this.associateSubActivities.push(subActivity);
      this.selectedSubActivity = null;
    },

    /** Sauvegarde d'une activité */
    async save() {
      let success = true;

      // enregistrement de l'activité
      if (this.activityHasChanged) {
        try {
          let entity = JSON.parse(JSON.stringify(this.source));
          entity.name = this.name;
          entity.metier = this.metierId;
          entity.digitalName = this.digitalName;
          entity.fullName = this.fullName;
          entity.label = this.label;
          entity.slug = this.slug;

          await this.serviceActivities.updateActivity(entity);
          this.source = JSON.parse(JSON.stringify(entity));
        } catch (error) {
          success = false;
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement de l'activité : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }
      // Sauvegarde les changements de dops
      if (success) {
        success = await this.saveDopsOfActivity();
      }
      // Sauvegarde les changements de sous-activité
      if (success) {
        success = await this.saveSubactivityOfActivity();
      }

      if (success) {
        this.disableAlertQuit();
        this.$router.go(-1);
      }
    },

    /** Sauvegarde les changements de dops de l'activité */
    async saveDopsOfActivity() {
      let success = true;

      /** Sauvegarde uniquement s'il y a eu des changements de dops */
      if (this.dopsHasChanged) {
        /** Les dops à ajouter */
        let added = [];
        this.associateDops.forEach((da) => {
          let found = this.sourceAssociateDops.find(
            (db) => da.idDop == db.idDop
          );
          if (!found) {
            added.push(da);
          }
        });

        /** Les dops à supprimer */
        let deleted = [];
        this.sourceAssociateDops.forEach((da) => {
          let found = this.associateDops.find((db) => da.idDop == db.idDop);
          if (!found) {
            deleted.push(da);
          }
        });

        try {
          /** On enregistre les dops à ajouter dans la bd */
          for (let i = 0; i < added.length; i++) {
            let dop = {};
            dop.manager = added[i].idDop;
            dop.perimeter = added[i].perimeter;
            await this.serviceActivities.addDopToActivity(this.activityId, dop);
          }

          /** On supprime l'association dop - activité */
          for (let i = 0; i < deleted.length; i++) {
            await this.serviceActivities.deleteDopToActivity(
              this.activityId,
              deleted[i].idDop
            );
          }
        } catch (error) {
          success = false;
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement des dops : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      } // End of if(dosHasChanged)

      return success;
    },

    /** Sauvegarde les changements de sous-activité de l'activité */
    async saveSubactivityOfActivity() {
      let success = true;

      // enregistrement de la partie sous-activité
      if (this.subActivitiesHaveChanged) {
        //les sous-activité à ajouter
        let added = [];
        this.associateSubActivities.forEach((ra) => {
          let found = this.sourceAssociateSubActivities.find(
            (rb) => rb.id == ra.id
          );
          if (!found) {
            added.push(ra);
          }
        });

        //les sous-activités à supprimer
        let deleted = [];
        this.sourceAssociateSubActivities.forEach((ra) => {
          let found = this.associateSubActivities.find((rb) => rb.id == ra.id);
          if (!found) {
            deleted.push(ra);
          }
        });

        try {
          //on ajoute les sous-activités à l'activité
          for (let i = 0; i < added.length; i++) {
            await this.serviceActivities.addSubActivityToActivity(
              this.activityId,
              added[i].id
            );
          }

          //on supprime les sous-activités de l'activité
          for (let i = 0; i < deleted.length; i++) {
            await this.serviceActivities.deleteSubActivityToActivity(
              this.activityId,
              deleted[i].id
            );
          }
        } catch (error) {
          success = false;
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement des sous-activités : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }

      return success;
    },

    /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.digitalName = trimString(this.digitalName);
    },
  },
  computed: {
    availableDops() {
      let available = [];
      let ids = this.associateDops.map((d) => d.idDop);
      available = this.allDops.filter((d) => !ids.includes(d.id));

      return available;
    },

    availableSubActivities() {
      let availables = [];
      let ids = this.associateSubActivities.map((r) => r.id);
      availables = this.allSubActivities.filter((r) => !ids.includes(r.id));

      return availables;
    },

    canAddDop() {
      if (!this.selectedDop) {
        return false;
      }
      if (
        this.perimeterSelectedDop == undefined ||
        this.perimeterSelectedDop == null
      ) {
        return false;
      }
      return true;
    },

    completed() {
      if (!this.name) return false;
      if (!this.metierId) return false;

      return true;
    },

    hasChanged() {

      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;

      if (this.activityHasChanged) {
        changed = true;
      }

      if (this.dopsHasChanged) {
        changed = true;
      }

      if (this.subActivitiesHaveChanged) {
        changed = true;
      }

      return changed;
    },
    /**retourne true si l'activité est modifiée (ne concerne pas les sous-activités: voir subActivitiesHaveChanged)  */
    activityHasChanged() {
      let changed = false;

      if (!areTheSame(this.source.name, this.name)) {
        changed = true;
      }

      if (this.source.metier != this.metierId) {
        changed = true;
      }

      if (!areTheSame(this.source.digitalName, this.digitalName.trim())) {
        changed = true;
      }

      if (this.source.fullName != this.fullName) {
        changed = true;
      }

      if (!areTheSame(this.source.label, this.label)) {
        changed = true;
      }

      if (!areTheSame(this.source.slug, this.slug)) {
        changed = true;
      }

      return changed;
    },

    /** Retourne true si les dops ont changés */
    dopsHasChanged() {
      let changed = false;

      this.associateDops.forEach((da) => {
        let found = this.sourceAssociateDops.find((db) => db.idDop == da.idDop);
        if (!found) {
          changed = true;
        }
      });

      /** Pour éviter d'analyser la fonction inverse précédente, si on a déjà détecté du changement, on le retourne */
      if (changed) {
        return true;
      }

      this.sourceAssociateDops.forEach((da) => {
        let found = this.associateDops.find((db) => da.idDop == db.idDop);
        if (!found) {
          changed = true;
        }
      });

      return changed;
    },

    /** retourne true si les sous-activité ont changés */
    subActivitiesHaveChanged() {
      let changed = false;
      this.associateSubActivities.forEach((ra) => {
        let found = this.sourceAssociateSubActivities.find(
          (rb) => rb.id == ra.id
        );
        if (!found) {
          changed = true;
        }
      });

      if (changed) {
        return true;
      }

      this.sourceAssociateSubActivities.forEach((ra) => {
        let found = this.associateSubActivities.find((rb) => rb.id == ra.id);
        if (!found) {
          changed = true;
        }
      });

      return changed;
    },
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.serviceActivities = new ActivityService(this.$api);
    this.serviceMetiers = new MetierService(this.$api.getMetierApi());
    this.serviceSubActivities = new SubActivitiesService(this.$api);
    this.serviceConf = new AppConsulterModifierService(
      this.$api.getAppConsulterModifierApi()
    );
    this.serviceUsers = new UsersService(this.$api.getUsersApi());

    this.serviceEstablishment = new EstablishmentsService(this.$api);
    
    this.viewActivityService = new ViewActivityService(this.serviceActivities, this.serviceUsers);

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.activityId = this.$route.params.id;
    }

    this.load();
  },
};
</script>
